import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {SecondaryCodeModel} from '@matchsource/models/secondary-codes';
import {SecondaryCodeControllerService} from '@matchsource/api-generated/subject';
import {secondaryCodeSerializer} from './secondary-code.serializer';
import {setSingleErrorCustomErrorHandlingContext, ClientErrorCode} from '@matchsource/error-handling/core';

@Injectable({
  providedIn: 'root',
})
export class SecondaryCodesApiService {
  constructor(private readonly secondaryCodeController: SecondaryCodeControllerService) {}

  getCodes(tcId: MsApp.Guid): Observable<SecondaryCodeModel[]> {
    if (!tcId) {
      return of([]);
    }

    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingSecondaryCodes);

    return this.secondaryCodeController
      .getAllForTc(
        {
          tcGuid: tcId,
        },
        {context: context()}
      )
      .pipe(map(codes => codes.map(code => secondaryCodeSerializer.fromDTO(code))));
  }

  saveCode(code: SecondaryCodeModel): Observable<SecondaryCodeModel> {
    if (code.isNew) {
      return this.createCode(code);
    }

    return this.updateCode(code);
  }

  private createCode(code: SecondaryCodeModel): Observable<SecondaryCodeModel> {
    return this.secondaryCodeController
      .create({
        body: secondaryCodeSerializer.toDTO(code),
      })
      .pipe(switchMap(ret => this.getCode(ret.guid)));
  }

  private updateCode(code: SecondaryCodeModel): Observable<SecondaryCodeModel> {
    return this.secondaryCodeController
      .update({
        body: secondaryCodeSerializer.toDTO(code),
      })
      .pipe(switchMap(() => this.getCode(code.id)));
  }

  private getCode(id: MsApp.Guid): Observable<SecondaryCodeModel> {
    return this.secondaryCodeController
      .getByGuid({
        guid: id,
      })
      .pipe(map(code => secondaryCodeSerializer.fromDTO(code)));
  }
}
