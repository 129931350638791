import {Serializer} from '@matchsource/api-utils';
import {SecondaryCodeModel} from '@matchsource/models/secondary-codes';
import {SecondaryCode} from '@matchsource/api-generated/subject';

export const secondaryCodeSerializer: Serializer<SecondaryCodeModel, SecondaryCode> = {
  toDTO(model: SecondaryCodeModel): SecondaryCode {
    return {
      guid: model.id,
      address1: model.address1,
      address2: model.address2,
      centerName: model.centerName,
      city: model.city,
      countryCode: model.countryCode,
      state: model.state,
      status: model.status,
      tcGuid: model.tcGuid,
      tcSecondaryCode: model.tcSecondaryCode,
      zipCode: model.zipCode,
      statusUpdateDate: model.statusDate,
    };
  },

  fromDTO(input: SecondaryCode): SecondaryCodeModel {
    return {
      id: input.guid || null,
      address1: input.address1,
      address2: input.address2,
      centerName: input.centerName,
      city: input.city,
      countryCode: input.countryCode,
      state: input.state,
      status: !!input.status,
      statusDate: input.statusUpdateDate,
      tcGuid: input.tcGuid,
      tcSecondaryCode: input.tcSecondaryCode,
      zipCode: input.zipCode,
      isNew: !input.guid,
    };
  },
};
